import "./AnalysisPage.css";
import "../../styles/common.css";

import { useParams } from "react-router-dom";
import AnalysisDetail from "../../components/AnalysisDetail/AnalysisDetail";

function AnalysisPage() {
    const { analysisId } = useParams();

    return (
        <div className="analysis-container">
            <div className="analysis-items">
                <AnalysisDetail analysisId={analysisId} />
            </div>
        </div>
    );
}

export default AnalysisPage;
