import "./DashboardPage.css";
import FoveaAILogo from "../../assets/images/foveaai_logo_dark_text.svg";

import React from "react";
import JobDetailForm from "../../components/JobDetailForm/JobDetailForm";
import JobTable from "../../components/JobTable/JobTable";
import { Modal } from "react-bootstrap";
import { Container, Button } from "reactstrap";

function DashboardPage() {
    const [showEditModal, setShowEditModal] = React.useState(false);

    const handleEditModalClose = () => {
        setShowEditModal(false);
    };

    return (
        <Container>
            <Modal
                show={showEditModal}
                onHide={() => setShowEditModal(false)}
                size="lg"
                aria-labelledby="contained-model-title-vcenter"
                centered
                dialogClassName="job-detail-form-model-custom"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-model-title-vcenter">
                        Add New Job Description
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="job-detail-form-modal-body-custom">
                    <div className="dashboard-new-job-modal-description">
                        <h4>Creating a Job Description</h4>
                        <p>
                            Fill out the fields below to add a new job to
                            FoveaAI. For the best evaluation results, pay
                            particular attention to the most important skills,
                            experience, and education you would like to see in
                            the ideal candidate.
                        </p>
                        <h6><b>Ensuring the Best Candidate Evaluations</b></h6>
                        <p>
                            Be thoughtful in your job description. While all
                            fields are considered, <b>extra attention is paid to
                            the job title and the key requirements.</b> Key
                            requirements are the most important skills and
                            experience that a candidate must have to be
                            successful. Essentially, if a candidate meets the
                            key requirements, you would like to know more about
                            them.
                        </p>
                        <h6><b>Years of Job Specific Experience</b></h6>
                        <p>
                            Include both the minimum and maximum number of years
                            of job specific experience you would like to see in a candidate.
                            Maximum years of exerpience is a non-standard
                            metric. It can be considered that candidates above
                            this value are overqualified for both the position
                            and the compensation offered.
                        </p>
                    </div>
                    <JobDetailForm
                        jobId={null}
                        closeModal={handleEditModalClose}
                    />
                </Modal.Body>
            </Modal>

            <div className="dashboard-info-container">
                <img
                    className="dashboard-info-logo"
                    src={FoveaAILogo}
                    alt="FoveaAI Logo"
                />
                {/* <div className="dashboard-tagline">
                    Identify top candidates in minutes.
                </div> */}
            </div>
            <div className="dashboard-container">
                <div className="dashboard-heading-container">
                    <div className="common-page-heading">
                        <h1>Open Jobs</h1>
                    </div>
                    <Button
                        color="success"
                        className="common-buttons-container"
                        onClick={() => setShowEditModal(true)}
                    >
                        Add Job
                    </Button>
                </div>
                <JobTable />
            </div>
        </Container>
    );
}

export default DashboardPage;
