import "./JobDetail.css";

import JobDetailForm from "../JobDetailForm/JobDetailForm";
import AddCandidatesDialog from "../AddCandidatesDialog/AddCandidatesDialog";
import LoadingOverlay from "../LoadingOverlay/LoadingOverlay";
import MatchBiasSlider from "../MatchBiasSlider/MatchBiasSlider";

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";
import { Modal } from "react-bootstrap";
import { Button } from "reactstrap";
import { useNavigate } from "react-router-dom";

// Call the JobDetail component either with a jobId, or a jobId and a job object
// Use the ref to update the jobId
const JobDetail = ({
    jobId,
    triggerReload,
    reloadTrigger,
    job_object = null,
}) => {
    const [job, setJob] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showAddCandidatesModal, setShowAddCandidatesModal] = useState(false);
    const [cookies] = useCookies(["AuthorizationToken"]);
    const [isLoading, setIsLoading] = useState(false);
    const [matchSliderValue, setMatchSliderValue] = useState(50);
    const navigate = useNavigate();

    useEffect(() => {
        // Declare an async function to fetch the job details inside the useEffect hook
        const fetchJobDetails = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(
                    `https://api.foveaai.com/evaluate/web/jobs/${jobId}`,
                    {
                        headers: { Authorization: cookies.AuthorizationToken },
                    }
                );
                const jobData =
                    typeof response.data === "string"
                        ? JSON.parse(response.data)
                        : response.data;
                setJob(jobData);

                // Set the match slider value to the job's match_bias
                // if the job object is fetched successfully and has a match_bias
                // that is not null or "".
                if (
                    jobData.match_bias !== null &&
                    jobData.match_bias !== undefined
                ) {
                    setMatchSliderValue(jobData.match_bias);
                }
            } catch (error) {
                console.error("Error fetching job details:", error);
            } finally {
                setIsLoading(false);
            }
        };

        // Fetch the job details if the job object is not provided
        if (job_object == null) {
            fetchJobDetails();
        }

        // Otherwise, use the job object
        else {
            setJob(job_object);
        }
    }, [jobId, job_object, cookies.AuthorizationToken, reloadTrigger]);

    const handleMatchSliderChange = (value) => {
        // The slider is disabled, so no need to provide functionality
    };

    const handleEditModalClose = () => {
        setShowEditModal(false);
    };

    const handleAddCandidatesModalClose = () => {
        setShowAddCandidatesModal(false);
    };

    const handleDeleteJob = async () => {
        setIsLoading(true);
        try {
            // Display a confirmation dialog before deleting the job
            if (
                !window.confirm(
                    "Are you sure you want to delete this job and all associated evaluations? This action cannot be undone."
                )
            ) {
                return;
            }

            // Delete the job using the endpoint
            await axios.delete(
                `https://api.foveaai.com/evaluate/web/jobs/${jobId}`,
                {
                    headers: { Authorization: cookies.AuthorizationToken },
                }
            );

            // Redirect to the jobs page
            navigate("/dashboard");
        } catch (error) {
            console.error("Error deleting job:", error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="job-detail-container">
            {isLoading && <LoadingOverlay />}
            <Modal
                show={showEditModal}
                onHide={() => setShowEditModal(false)}
                size="lg"
                aria-labelledby="contained-model-title-vcenter"
                centered
                dialogClassName="job-detail-form-model-custom"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-model-title-vcenter">
                        Edit Current Job Description
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="job-detail-form-modal-body-custom">
                    <JobDetailForm
                        jobId={jobId}
                        closeModal={handleEditModalClose}
                        triggerReload={triggerReload}
                    />
                </Modal.Body>
            </Modal>

            <Modal
                show={showAddCandidatesModal}
                onHide={() => setShowAddCandidatesModal(false)}
                size="lg"
                aria-labelledby="contained-model-title-vcenter"
                centered
                dialogClassName="job-detail-form-model-custom"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-model-title-vcenter">
                        Add Candidates
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="job-detail-form-modal-body-custom">
                    <AddCandidatesDialog
                        job_id={jobId}
                        closeModal={handleAddCandidatesModalClose}
                    />
                </Modal.Body>
            </Modal>

            <div className="common-buttons-container">
                <Button
                    color="dark"
                    className="job-button"
                    onClick={() => navigate("/dashboard")}
                >
                    Back
                </Button>
                <Button
                    color="secondary"
                    className="job-button"
                    onClick={() => setShowEditModal(true)}
                >
                    Edit
                </Button>
                <Button
                    color="danger"
                    className="job-button"
                    onClick={handleDeleteJob}
                >
                    Delete Job
                </Button>
                <Button
                    color="success"
                    className="job-button"
                    onClick={() => setShowAddCandidatesModal(true)}
                >
                    Add Candidates
                </Button>
            </div>

            <div className="common-detail">
                <div className="common-detail-container-main-heading">
                    {job ? job.title : ""}
                </div>
                <div className="common-detail-grid">
                    <div className="common-detail-label">Client:</div>
                    <div className="common-detail-value">
                        {job ? job.client : ""}
                    </div>
                    <div className="common-detail-label">Contact:</div>
                    <div className="common-detail-value">
                        {job ? job.contact : ""}
                    </div>
                    <div className="common-detail-label">Industry:</div>
                    <div className="common-detail-value">
                        {job ? job.industry : ""}
                    </div>
                </div>
            </div>

            <div className="common-detail">
                <div className="common-detail-container-heading">
                    Job Description
                </div>
                <div className="common-detail-grid">
                    <div className="common-detail-label">
                        Key Requirements:
                    </div>
                    <div className="common-detail-value">
                        {job ? job.key_requirements : ""}
                    </div>
                    <div className="common-detail-label">
                        Description:
                    </div>
                    <div className="common-detail-value">
                        {job ? job.description : ""}
                    </div>
                    <div className="common-detail-label">
                        Requirements:
                    </div>
                    <div className="common-detail-value">
                        {job ? job.requirements : ""}
                    </div>
                    <div className="common-detail-label">
                        Desirables:
                    </div>
                    <div className="common-detail-value">
                        {job ? job.desirables : ""}
                    </div>
                    <div className="common-detail-label">Skills:</div>
                    <div className="common-detail-value">
                        {job ? job.skills : ""}
                    </div>
                </div>
            </div>

            <div className="common-detail">
                <div className="common-detail-container-heading">
                    Job Related Experience
                </div>
                <div className="common-detail-grid">
                    <div className="common-detail-label">Min Years:</div>
                    <div className="common-detail-value">
                        {job ? job.min_years : ""}
                    </div>
                    <div className="common-detail-label">Max Years:</div>
                    <div className="common-detail-value">
                        {job ? job.max_years : ""}
                    </div>
                    <div className="common-detail-label">
                        Match Flexibility:
                    </div>
                    <div className="common-detail-value slider">
                        <MatchBiasSlider
                            matchSliderValue={matchSliderValue}
                            onMatchSliderChange={handleMatchSliderChange}
                            disabled={false}
                        />
                    </div>
                </div>
            </div>

            <div className="common-detail">
                <div className="common-detail-container-heading">
                    Job Location
                </div>
                <div className="common-detail-grid">
                    <div className="common-detail-label">City:</div>
                    <div className="common-detail-value">
                        {job ? job.locality : ""}
                    </div>
                    <div className="common-detail-label">State:</div>
                    <div className="common-detail-value">
                        {job ? job.administrative_area : ""}
                    </div>
                    <div className="common-detail-label">Country:</div>
                    <div className="common-detail-value">
                        {job ? job.country : ""}
                    </div>
                    <div className="common-detail-label">Postal Code:</div>
                    <div className="common-detail-value">
                        {job ? job.postal_code : ""}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JobDetail;
