import "./AnalysisTable.css";
import "../../styles/common.css";

import LoadingOverlay from "../LoadingOverlay/LoadingOverlay";
import { GetColorForRating } from "../../utils/FormattingUtils";

import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table, Form, Tabs, Tab } from "react-bootstrap";
import { Button } from "reactstrap";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import InfoIcon from "../InfoIcon/InfoIcon";

const AnalysisTable = ({ jobId, triggerReload, reloadTrigger }) => {
    const [analyses, setAnalyses] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [sortConfig, setSortConfig] = useState(() => {
        // Load the sort configuration from local storage if available
        const savedSortConfig = localStorage.getItem("analysisSortConfig");
        const config = savedSortConfig
            ? JSON.parse(savedSortConfig)
            : { key: null, direction: "ascending" };
        return config;
    });
    const [cookies] = useCookies(["AuthorizationToken"]);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [ratingType, setRatingType] = useState(() => {
        return localStorage.getItem("analysisRatingType") || "jobFit";
    });

    useEffect(() => {
        const fetchStatuses = async () => {
            try {
                const response = await axios.get(
                    "https://api.foveaai.com/evaluate/web/analyses/statuses",
                    {
                        headers: { Authorization: cookies.AuthorizationToken },
                    }
                );
                const statusesArray = Array.isArray(response.data) ? response.data : response.data.statuses;
                setStatuses(statusesArray.sort((a, b) => a.status_order_priority - b.status_order_priority));
            } catch (error) {
                console.error("Error fetching statuses:", error);
            }
        };

        fetchStatuses();
    }, [cookies.AuthorizationToken]);

    useEffect(() => {
        const fetchAnalyses = async () => {
            let analyses_array = [];

            // Dim the component during the load
            setIsLoading(true);
            try {
                // Get all of the analyses for the job
                const response = await axios.get(
                    `https://api.foveaai.com/evaluate/web/analyses/jobs/${jobId}`,
                    {
                        headers: { Authorization: cookies.AuthorizationToken },
                    }
                );

                const response_data =
                    typeof response.data === "string"
                        ? JSON.parse(response.data)
                        : response.data;

                analyses_array = Array.isArray(response_data)
                    ? response_data
                    : [response_data];

                console.log("Candidates found for job:", analyses_array);
            } catch (error) {
                console.error("Error fetching candidates for the job:", error);
            } finally {
                // Remove the dim from the component
                setIsLoading(false);
            }

            setAnalyses(analyses_array);
        };

        fetchAnalyses();
    }, [jobId, cookies.AuthorizationToken, reloadTrigger]);

    useEffect(() => {
        // Save the sort configuration to local storage
        localStorage.setItem("analysisSortConfig", JSON.stringify(sortConfig));
    }, [sortConfig]);

    useEffect(() => {
        // Save the rating type to local storage
        localStorage.setItem("analysisRatingType", ratingType);
    }, [ratingType]);

    const requestSort = (key) => {
        let direction = "ascending";
        if (sortConfig.key === key && sortConfig.direction === "ascending") {
            direction = "descending";
        }
        setSortConfig({ key, direction });
    };

    const getValueByPath = (object, path) => {
        return path.split(".").reduce((o, p) => (o ? o[p] : undefined), object);
    };

    const sortIndicator = (columnName) => {
        if (sortConfig.key === columnName) {
            return sortConfig.direction === "ascending" ? "▲" : "▼";
        }
        return "";
    };

    const sortedAnalyses = React.useMemo(() => {
        console.log("Analyses in the sort:", analyses);
        let sortableItems = [...analyses];
        if (sortConfig.key !== null) {
            sortableItems.sort((a, b) => {
                const aValue = getValueByPath(a, sortConfig.key);
                const bValue = getValueByPath(b, sortConfig.key);

                if (aValue < bValue) {
                    return sortConfig.direction === "ascending" ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortConfig.direction === "ascending" ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableItems;
    }, [analyses, sortConfig]);

    const handleRatingTypeChange = (e) => {
        // Handle rating type change
        const newRatingType = e.target.checked ? "totalExperience" : "jobFit";
        setRatingType(newRatingType);

        // Update sorting if the current sort is on the rating column
        if (
            sortConfig.key === "job_fit_rating_normalized" ||
            sortConfig.key === "total_experience_rating_normalized"
        ) {
            const newKey =
                newRatingType === "jobFit"
                    ? "job_fit_rating_normalized"
                    : "total_experience_rating_normalized";
            setSortConfig({ ...sortConfig, key: newKey });
        }
    };

    const getRatingKey = () => {
        // Get the key for the rating column
        return ratingType === "jobFit"
            ? "job_fit_rating_normalized"
            : "total_experience_rating_normalized";
    };

    return (
        <>
            {isLoading && <LoadingOverlay />}
            <div className="buttons-container">
                <Button
                    color="secondary"
                    className="analysis-button"
                    onClick={() => triggerReload()}
                >
                    Refresh Candidates
                </Button>
            </div>
            <div className="analysis-tabs">
                <Tabs
                    defaultActiveKey={statuses[0]?.status_id}
                    id="analysis-tabs"
                    className="analysisTabs"
                >
                    {statuses.map((status) => (
                        <Tab eventKey={status.status_id} title={status.status_name} key={status.status_id} className="analysisTab">
                            <div className="analysis-table">
                                <Table className="common-table" bordered hover>
                                    <thead>
                                        <tr>
                                            <th onClick={() => requestSort("viewed")}>
                                                NEW {sortIndicator("viewed")}
                                            </th>
                                            <th>
                                                <div className="rating-header">
                                                    <span
                                                        onClick={() =>
                                                            requestSort(getRatingKey())
                                                        }
                                                    >
                                                        {ratingType === "jobFit"
                                                            ? "JOB FIT"
                                                            : "TOTAL EXPERIENCE"}{" "}
                                                        {sortIndicator(getRatingKey())}
                                                    </span>
                                                    <Form.Check
                                                        type="switch"
                                                        id="rating-type-switch"
                                                        label=""
                                                        checked={
                                                            ratingType === "totalExperience"
                                                        }
                                                        onChange={handleRatingTypeChange}
                                                        className="rating-switch"
                                                    />
                                                    <InfoIcon
                                                        title="Rating Type"
                                                        content="Switch between Job Fit and Total Experience ratings. Job Fit reduces candidate rating for under and over qualified candidates. Total Experience rates candidates from the most to least job related experience."
                                                    />
                                                </div>
                                            </th>
                                            <th onClick={() => requestSort("last_name")}>
                                                CANDIDATE {sortIndicator("last_name")}
                                            </th>
                                            <th
                                                onClick={() => requestSort("primary_job_title")}
                                            >
                                                PRIMARY JOB TITLE{" "}
                                                {sortIndicator("primary_job_title")}
                                            </th>
                                            <th onClick={() => requestSort("locality")}>
                                                CITY {sortIndicator("locality")}
                                            </th>
                                            <th
                                                onClick={() =>
                                                    requestSort("administrative_area")
                                                }
                                            >
                                                STATE {sortIndicator("administrative_area")}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ cursor: "pointer" }}>
                                        {Array.isArray(analyses) && analyses.length > 0 ? (
                                            sortedAnalyses
                                                .filter(analysis => analysis.status_id === status.status_id)
                                                .map((analysis, index) => (
                                                    <tr
                                                        key={index}
                                                        onClick={() =>
                                                            navigate(
                                                                `/analysis/${analysis.analysis_id}`
                                                            )
                                                        }
                                                    >
                                                        <td>
                                                            {!analysis.viewed && (
                                                                <div className="new-circle"></div>
                                                            )}
                                                        </td>
                                                        <td>
                                                            <div
                                                                className="rating-circle-table"
                                                                style={{
                                                                    backgroundColor:
                                                                        GetColorForRating(
                                                                            analysis,
                                                                            ratingType
                                                                        ),
                                                                }}
                                                            >
                                                                {analysis[getRatingKey()]}
                                                            </div>
                                                        </td>
                                                        <td className="common-table-cell-link">
                                                            {analysis.first_name}{" "}
                                                            {analysis.last_name}
                                                        </td>
                                                        <td>{analysis.primary_job_title}</td>
                                                        <td>{analysis.locality}</td>
                                                        <td>{analysis.administrative_area}</td>
                                                    </tr>
                                                ))
                                        ) : (
                                            <tr>
                                                <td colSpan="6">
                                                    Evaluate your first candidates for this job
                                                    using the Add Candidates button above
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        </Tab>
                    ))}
                </Tabs>
            </div>
        </>
    );
};

export default AnalysisTable;
