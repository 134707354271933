import "./JobPage.css";
import "../../styles/common.css";

import React, { useState } from "react";
import { useParams } from "react-router-dom";
import JobDetail from "../../components/JobDetail/JobDetail";
import AnalysisTable from "../../components/AnalysisTable/AnalysisTable";

function JobPage() {
    const { jobId } = useParams(); // Getting jobId from the URL
    const [reloadTrigger, setReloadTrigger] = useState(Math.random());

    const triggerReload = () => {
        setReloadTrigger(Math.random());
    };

    return (
        <div className="job-container">
            <div className="job-items">
                {/* <div className="common-page-heading">
                    <h1>Job Description</h1>
                </div>
                <div className="common-page-instructions">
                    Click "Add Candidates" to upload resumes and perform
                    candidate evaluations. Completed evaluations will be
                    displayed in the Candidate Evaluations list below once they
                    are ready. Click refresh on your browser or return to this
                    page later to see the results.
                </div> */}
                <JobDetail jobId={jobId} triggerReload={triggerReload} reloadTrigger={reloadTrigger} />
                <div className="common-page-heading">
                    <h1>Candidate Evaluations</h1>
                </div>
                <AnalysisTable jobId={jobId} triggerReload={triggerReload} reloadTrigger={reloadTrigger} />
            </div>
        </div>
    );
}

export default JobPage;
